import Api, { ApiOptions } from './ApiProvider';
import { AutoLayerCampaignParticipant, IntegrationCampaignData } from './types';
import { PaginationSearchParams } from './types/common';
import {
  CampaignLeaderboardItem,
  CampaignParticipantList,
  DeserializedZealyRef,
  DeserializedZealyTask,
  NormalizedZealyCampaign,
  SelfCampaignLeaderboardMe,
  StormCampaignParticipant,
  Story,
  ZealyStoryLeaderboard,
  ZealyStoryTaskParticipant,
  ZealyTaskList,
} from './types/story';

export const getStory = async (
  options: ApiOptions<{
    searchParams: {
      id: string;
    };
  }>,
) => {
  return Api.get(`v2/store/story/${options.searchParams.id}`, options).json<{ story: Story }>();
};

export const DEFAULT_PARTICIPANT_LIST_LIMIT = 20;

export const getZealyParticipants = async (
  options?: ApiOptions<{
    searchParams: {
      limit?: number;
      page?: number;
    };
  }>,
) => {
  const optionsWithDefaults = {
    ...options,
    searchParams: {
      limit: DEFAULT_PARTICIPANT_LIST_LIMIT,
      ...options?.searchParams,
    },
  };
  const data = await Api.get(
    'v2/hub/campaign/tge/participants',
    optionsWithDefaults,
  ).json<ZealyStoryLeaderboard>();

  return data.data.reduce(
    (acc, dataItem) => {
      if (dataItem.type === 'ZEALY_PARTICIPANT') {
        acc.participants.push(dataItem);
      } else if (dataItem.type === 'ZEALY_LEADERBOARD') {
        acc.totalParticipants = dataItem.attributes.totalParticipants;
        acc.rewardCurrency = dataItem.attributes.reward;
        acc.totalPages = dataItem.attributes.totalPages;
      }

      return acc;
    },
    {
      participants: [] as ZealyStoryTaskParticipant[],
      rewardCurrency: 'SQR',
      totalPages: 0,
      totalParticipants: 0,
    },
  );
};

export const getZealyCampaign = async (options?: ApiOptions) => {
  const data = await Api.get('v2/hub/campaign/tge', options).json<ZealyTaskList>();
  const normalizedData = data.data.reduce(
    (acc, dataItem) => {
      if (dataItem.type === 'ZEALY_CATEGORY') {
        acc.categories.push(dataItem);
      } else if (dataItem.type === 'ZEALY_TASK') {
        acc.quests.push(dataItem);
      } else if (dataItem.type === 'ZEALY_REF') {
        acc.account = dataItem;
      } else if (dataItem.type === 'ZEALY_CAMPAIGN') {
        acc.campaign = dataItem;
      }

      return acc;
    },
    {
      categories: [],
      quests: [],
      account: null,
      campaign: null,
    } as NormalizedZealyCampaign,
  );

  return normalizedData;
};

export interface ZealyAccount {
  account: DeserializedZealyRef;
  quests: Record<number, DeserializedZealyTask>;
}

export const getZealyAccount = async (options?: ApiOptions): Promise<ZealyAccount | null> => {
  const data = await getZealyCampaign(options);

  if (!data.account) {
    return null;
  }

  const quests = data.quests.reduce((acc, quest) => {
    acc[quest.id] = quest;

    return acc;
  }, {} as Record<number, DeserializedZealyTask>);

  return {
    account: data.account,
    quests,
  };
};

export const verifyZealy = async (options?: ApiOptions) => {
  return Api.post('v2/hub/campaign/tge/verify', options).json<DeserializedZealyRef>();
};

export const getStormTradeCampaignParticipants = async (
  options?: ApiOptions<{
    searchParams: {
      limit?: number;
      page?: number;
    };
  }>,
) => {
  const optionsWithDefaults = {
    ...options,
    searchParams: {
      limit: DEFAULT_PARTICIPANT_LIST_LIMIT,
      ...options?.searchParams,
    },
  };

  return Api.get(
    'v2/hub/campaign/storm-trade/participants',
    optionsWithDefaults,
  ).json<CampaignParticipantList>();
};

export const getStormTradeSelfPosition = () => {
  return Api.get(`v2/hub/campaign/storm-trade/me`).json<StormCampaignParticipant>();
};

export const getAutoLayerCampaignParticipants = async (
  options?: ApiOptions<{
    searchParams: {
      limit?: number;
      offset?: number;
    };
  }>,
) => {
  const optionsWithDefaults: typeof options = {
    ...options,
    searchParams: {
      limit: DEFAULT_PARTICIPANT_LIST_LIMIT,
      offset: 0,
      ...options?.searchParams,
    },
  };

  return Api.get(
    'v2/hub/campaign/autolayer/autolayer-and-soquest/leaderboard',
    optionsWithDefaults,
  ).json<AutoLayerCampaignParticipant[]>();
};

export const getSelfAutoLayerCampaignParticipant = async (options?: ApiOptions) => {
  return Api.get(
    'v2/hub/campaign/autolayer/autolayer-and-soquest/leaderboard/me',
    options,
  ).json<AutoLayerCampaignParticipant>();
};

export const CAMPAIGN_LEADERBOARD_DEFAULT_PAGE_LIMIT = 20;

export const getCampaignLeaderboard = async (
  campaignSlug: string,
  options?: ApiOptions<{ searchParams?: PaginationSearchParams }>,
) => {
  const optionsWithDefaults: typeof options = {
    ...options,
    searchParams: {
      limit: CAMPAIGN_LEADERBOARD_DEFAULT_PAGE_LIMIT,
      // NOTE: offset = current page, so you need to just add 1 to get next page
      offset: 0,
      ...options?.searchParams,
    },
  };

  const data = await Api.get(
    `v2/hub/campaign/${campaignSlug}/leaderboard`,
    optionsWithDefaults,
  ).json<CampaignLeaderboardItem[]>();

  return data;
};

export const getSelfCampaignLeaderboardMe = async (campaignSlug: string, options?: ApiOptions) => {
  const data = await Api.get(
    `v2/hub/campaign/${campaignSlug}/leaderboard/me`,
    options,
  ).json<SelfCampaignLeaderboardMe>();

  return data;
};

export const getIntegrationCampaignLeaderboard = async (
  integrationSlug: string,
  campaignSlug: string,
  options?: ApiOptions<{ searchParams?: PaginationSearchParams }>,
) => {
  const data = await Api.get(
    `v2/integrations/${integrationSlug}/leaderboard/${campaignSlug}`,
    options,
  ).json<IntegrationCampaignData>();

  return data;
};
