import Api from '@shared/api/ApiProvider';
import { TurtleClubMembership } from '@shared/api/types/turtle-club';

export interface VerifyTurtleClubSiweParameters {
  address: string;
  network?: string;
  signupToken: string;
  signature: string;
}

interface FetchTurtleClubSiweMessageParameters {
  address: string;
}

interface FetchTurtleClubSiweMessageResponse {
  nonce: string;
  signMessage: string;
  signupToken: string;
  timestamp: string;
}

export const getTurtleClubMembership = async () => {
  return Api.get(`v2/integrations/turtle-club/status`).json<TurtleClubMembership>();
};

export const fetchTurtleClubSiweMessage = async ({
  address,
}: FetchTurtleClubSiweMessageParameters): Promise<FetchTurtleClubSiweMessageResponse> => {
  const rawResponse = await Api.get(`v2/integrations/turtle-club/siwe-message`, {
    searchParams: {
      address,
    },
  }).json<Record<string, string>>();

  return {
    nonce: rawResponse.nonce,
    signMessage: rawResponse.sign_message,
    signupToken: rawResponse.signup_token,
    timestamp: rawResponse.timestamp,
  };
};

export const verifyTurtleClubSiwe = async ({
  address,
  network,
  signupToken,
  signature,
}: VerifyTurtleClubSiweParameters) => {
  return Api.post(`v2/integrations/turtle-club/verify-siwe`, {
    json: {
      signup_token: signupToken,
      address,
      signature,
      network,
    },
  }).json();
};
